// SocialPage.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { UserAuth } from '../context/AuthContext';
import SocialProfileForm from './../components/SocialProfileForm';
import ProfileFilters from './../components/ProfileFilters';
import ProfileCards from './../components/ProfileCards';
import LoginComponent from '../components/LoginComponent';

import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUsers, faPaw, faComments, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';

function SocialPage() {
    const { lang } = useParams();
    const { t } = useTranslation();
    const { user } = UserAuth();
    const [profiles, setProfiles] = useState([]);
    const [filters, setFilters] = useState({ tag: '' });

    // Fetch social profiles from your API
    useEffect(() => {
        axios
            .get('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/social')
            .then((response) => setProfiles(response.data))
            .catch((error) => console.error('Error fetching profiles: ', error));
    }, []);

    // Handle submission of a new social profile by a logged in user
    const handleProfileSubmit = async (formData) => {
        try {
            const response = await axios.post(
                'https://api.example.com/social-profiles',
                formData
            );
            // Prepend the new profile to the list
            setProfiles([response.data, ...profiles]);
        } catch (error) {
            console.error('Error submitting profile: ', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t("social.meta_title")}
                </title>
                <meta name="description" content={t('social.meta_description')} />
                <meta name="keywords" content={t('social.meta_keywords')} />
            </Helmet>

            {/* Hero Section */}
            <div className=" text-dark py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={7}>


                            <h1 className="my-4">{t('social.h1')} </h1>
                            <p className="text-muted">{t('social.description')}</p>
                            <div className="d-flex flex-wrap">
                                {/* <Button variant="outline-dark" size="md" className="mb-3  mb-lg-0 mr-lg-3" href="#profiles">
                                    <FontAwesomeIcon icon={faUsers} className="mr-2" /> {t("Browse Profiles")}
                                </Button> */}
                                {/* {!user && (
                                    <Button variant="outline-dark btn-small" size="md" href="#create-profile">
                                        <FontAwesomeIcon icon={faPaw} className="mr-2" /> {t("Join Community")}
                                    </Button>
                                )} */}
                            </div>
                        </Col>
                        <Col lg={5} className="text-center mt-4 mt-lg-0">
                            <img
                                loading="lazy"
                                src="/assets/web/pet-social.png"
                                alt="Connect with Pet Professionals and Pet Lovers"
                                className="img-fluid rounded shadow-lg"
                                style={{ maxHeight: '350px' }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Main Content */}
            <Container className="py-4" id="profiles">
                <Row>
                    {/* Left Column: App Download and User Benefits */}
                    <Col lg={4} className="mb-4">
                        <Card className="shadow-sm border-0 mb-4">
                            <Card.Body className="text-center">
                            <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                                <p>{t("Connect with more pet professionals and pet owners on the go")}</p>

                                {/* <img
                                    loading="lazy"
                                    style={{ maxWidth: '250px', height: 'auto' }}
                                    className="img-fluid mb-3"
                                    src="/assets/web/pet-connect.png"
                                    alt="Pets Home App - Connect with Pet Community"
                                /> */}

                                <div className="d-flex justify-content-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-2">
                                        <img style={{ width: '140px', height: 'auto', marginRight: "10px" }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                                    </a>
                                    <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer">
                                        <img style={{ width: '140px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                                    </a>
                                </div>

                                <div className="d-flex justify-content-center my-2">
                                    <div>
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                        <FontAwesomeIcon icon={faStar} color="gold" />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-3">
                                    <img style={{ width: '100px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-2" />
                                    <img style={{ width: '100px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                                </div>
                            </Card.Body>
                        </Card>

                        {/* <Card className="shadow-sm border-0 mb-4" id="create-profile">
                            <Card.Body>
                                <h4 className="text-center mb-3">{t("Join Our Pet Community")}</h4>
                                {!user ? (
                                    <div className="text-center">
                                        <p>{t("Sign in to create your profile and connect with pet owners and professionals.")}</p>
                                        <LoginComponent />
                                    </div>
                                ) : (
                                    <SocialProfileForm
                                        onSubmit={handleProfileSubmit}
                                        className="rounded bg-light p-2"
                                    />
                                )}
                            </Card.Body>
                        </Card> */}

                        {/* Benefits of Joining */}
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                                <h4 className="text-center mb-3">{t("Why Join Our Community?")}</h4>
                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center mb-3">
                                        <div className="bg-primary text-white rounded-circle d-flex justify-content-center align-items-center mr-3" style={{ width: '40px', height: '40px', minWidth: '40px', marginRight: '10px' }}>
                                            <FontAwesomeIcon icon={faUsers} />
                                        </div>
                                        <div>
                                            <h5 className="mb-0">{t("Expand Your Network")}</h5>
                                            <p className="mb-0 small">{t("Connect with pet owners and professionals in your area")}</p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <div className="bg-primary text-white rounded-circle d-flex justify-content-center align-items-center mr-3" style={{ width: '40px', height: '40px', minWidth: '40px', marginRight: '10px' }}>
                                            <FontAwesomeIcon icon={faHandshake} />
                                        </div>
                                        <div>
                                            <h5 className="mb-0">{t("Offer Your Services")}</h5>
                                            <p className="mb-0 small">{t("Promote your pet services to local pet owners")}</p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <div className="bg-primary text-white rounded-circle d-flex justify-content-center align-items-center mr-3" style={{ width: '40px', height: '40px', minWidth: '40px', marginRight: '10px' }}>
                                            <FontAwesomeIcon icon={faComments} />
                                        </div>
                                        <div>
                                            <h5 className="mb-0">{t("Share Experiences")}</h5>
                                            <p className="mb-0 small">{t("Exchange pet care tips and advice with the community")}</p>
                                        </div>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>

                        {/* Social Sharing Section */}
                        <div className="social-sharing text-center my-4">
                            <h5>{t("Share Our Community")}</h5>
                            <div className="mt-2">
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebook} size="lg" className="mx-2" />
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Connect%20with%20pet%20professionals%20and%20other%20pet%20owners!" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTwitter} size="lg" className="mx-2" />
                                </a>
                                <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="lg" className="mx-2" />
                                </a>
                                <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faPinterest} size="lg" className="mx-2" />
                                </a>
                                <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} size="lg" className="mx-2" />
                                </a>
                            </div>
                        </div>
                    </Col>

                    {/* Right Column: Community Profiles */}
                    <Col lg={8}>
                        {/* <h4 className="mb-3">{t("New Pet Community Members")}</h4> */}

                        {/* Original Filters Component */}
                        <ProfileFilters filters={filters} setFilters={setFilters} />

                        {/* Profile Cards */}
                        <ProfileCards profiles={profiles} filters={filters} />

                        {profiles.length === 0 && (
                            <div className="text-center p-5 bg-light rounded">
                                <h4>{t("Be the first to join our pet community!")}</h4>
                                <p>{t("Create your profile and start connecting with other pet lovers today.")}</p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SocialPage;